.Projects {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 3%;
}

/* The header */
.projectHeader{
    margin-top: 150px;
    margin-bottom: 50px;
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    width: 75%;
}
.githubLogo {
    color: #242838;
    display: flex;
    flex-direction: column;
    padding-right: 15px;
}
.github {
    font-size: 20px;
    color: #242838;
    margin-top: 2%;
}
.githubLogo:hover {
    opacity: 0.5;
}
.project-title{
    font-size: 30px;
    font-weight: 600;
}

/* Project Cards */
.projectCard {
    position: relative;
    width: 75%;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 88px;
}
.projectContent {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 50px;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: left;
    align-items: center;
    color: #242838;
    border-left: 2px solid #a7aab1;
    border-bottom: 2px solid #a7aab1;
}
.projectContent:hover {
    box-shadow: 16px 0px 16px 0px rgba(0,0,0,0.2);
}
.projectLink {
    font-size: 25px;
    font-weight: 600;
    color: #242838;
    margin-top: 20px;
}
.projectImageContainer{
    display: flex;
    float: right;
}
.projectImage {
    width: 125px;
    height: 125px;
}
.projectText {
    width: 80%;
    text-align: left;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 60px;
}
.projectGuthub:hover{
    opacity: 0.6;
}
/* .projectText a:hover{
    opacity: 0.5;
} */
.hammer{
    padding-left: 20px;
}


@media screen and (max-width: 1000px){
    .projectLink{
        font-size: 20px;
    }
}

@media screen and (max-width: 800px){
    .projectImage{
        width: 100px;
        height: 100px;
    }
}

@media screen and (max-width: 550px){
    .projectLink{
        font-size: 15px;
    }
    .date{
        font-size: 8px;
    }
    .projectImage{
        width: 75px;
        height: 75px;
    }
    .projectCard{
        width: 90%;
    }
}

@media screen and (max-width: 400px){
    .projectLink{
        font-size: 12px;
    }
    .date{
        font-size: 8px;
    }
    .AndMinutesImage{
        width: 50px;
        height: 50px;
    }
    .AndMinutesText{
        padding-right: 30px;
    }
}