.Home {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 105px;
}

.my-div{
    margin-top: 200px;
    width: 75%;
}

/* Moving Picture */
@keyframes morph {
    0% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
        background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    }
    50% {
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
        background: linear-gradient(45deg, var(--third) 0%, var(--secondary) 100%);
    }
    100% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
        background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%);
    }
}
.headshot {
    height: 25%;
    width: 25%;
    margin-top: 65px;
    float: left;
    animation: morph 6s ease-in-out infinite;
    border: 3px solid #62656a;
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    transition: all 1s ease-in-out;
    z-index: 5;
}

/* Info */
.my-info {
    width: 60%;
    float: right;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-around;
    align-items: center;
}
.title{
    padding-bottom: 15px;
    border-bottom: 2px solid #a7aab1;
    margin-bottom: 5px;
}
.software-engineer {
    font-size: 25px;
    margin-top: 15px;
}
.why{
    width: 80%;
}
.stack {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    padding-bottom: 15px;
    border-bottom: 2px solid #a7aab1;
    margin-bottom: 5px;
    width: 50%;
}
.logo-container{
    width: 100%;
    display: flex;
    justify-content: center;
}
#stack-logos {
    display: flex;
    justify-content: space-evenly;
    padding-top: 15px;
    width: 100%;
}


@media screen and (max-width: 1000px){
    .headshot{
        width: 30%;
        height: 30%;
    }
}

@media screen and (max-width: 800px){
    .my-div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .my-info{
        width: 90%;
    }
    .headshot{
        width: 40%;
        height: 40%;
    }
    .my-div{
        margin-top: 100px;
    }
    .Home{
        margin-bottom: 25px;
    }
}

@media screen and (max-width: 500px){
    .why{
        font-size: 12px;
        width: 100%;
    }
    .stack{
        margin-top: 50px;
    }
}

@media screen and (max-width: 350px){
    .why{
        font-size: 8px;
    }
    .title{
        font-size: 16px;
    }
    .software-engineer{
        font-size: 12px;
    }
    .stack{
        font-size: 12px;
    }
    #stack-logos{
        font-size: 8px;
    }
}