body {
  background-color: #fcfdff;
  text-align: center;
  justify-content: center;
  max-width: 100%;
  margin: 0%;
  font-family: "proxima-nova", sans-serif;
}

.header {
  position: absolute;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  height: 80px;
  margin-top: -20px;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: .5px solid#e8e9eb;
}
.header:hover{
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

/* Search Bar */
.search-container {
  display: flex;
  justify-content: center;
  width: 75%;
  margin-bottom: 50px;
}
input[type="text"] {
  outline: none;
  display: flex;
  width: 100%;
  height: 40px;
  font-size:15px;
  padding-left: 10px;
  font-family: inherit;
  text-align: left;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #a7aab1;
  background-color: #fcfdff;
  
}
input[type="text"]:hover{
  cursor: text;
  box-shadow: 0px 12px 10px 0px rgba(0,0,0,0.2);
}

.footer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 140px;
  background-color: #fcfdff;
  border-top: .5px solid#e8e9eb;
}
.footer:hover{
  box-shadow: 0px 8px 50px 0px rgba(0, 0, 0, 0.292);
}

/* Button to bring you to the top of the page */
.arrowIcon{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}
.backTool {
  position: relative;
  width: 100px;
  margin-left: 90%;
  margin-bottom: 1.5%;
  justify-content: center;
}
.backToTop {
  color: #242838;
  float: none;
  text-decoration: none;
  border: none;
  background-color: #fcfdff;
  border-radius: 25px;
  border: .5px solid#e8e9eb;
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}
.backToTop:hover{
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
}
.arrowIcon:hover>.tooltip {
  opacity: 1;
}
.tooltip {
  background-color: #fcfdff;
  font-size: 12px;
  color: #242838;
  padding: 5px;
  width: 75px;
  border-radius: 10px;
  position: relative;
  text-align: center;
  margin-bottom: 2.5px;
  opacity: 0; 
  transition: all 0.5s ease;
}



@media screen and (max-width: 850px){
    .backTool{
      margin-left: 85%;
    }
}

@media screen and (max-width: 600px){
  .backTool{
    margin-left: 80%;
  }
}

@media screen and (max-width: 500px){
  .backTool{
    margin-left: 75%;
  }
}

@media screen and (max-width: 350px){
  .backTool{
    margin-left: 65%;
  }
}
