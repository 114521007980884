.foot-div{
    display: inline-flex;
    color:#242838;
    padding-bottom: 5px;
}
#contact{
    text-decoration: none;
    font-size: 16px;
    color:#242838;
    padding-top: 30px;
    padding-bottom: 10px;
    margin: 0;
}
a {
    text-decoration: none;
    color:#242838;
    width:100%;
    text-decoration: none;
}
.footer-list {
    font-size: 12px;
    text-decoration: none;
    list-style: none;
    padding-left: 20px;
    padding-right: 20px;
}

/* Hyperlinks */
.phoneNum {
    font-size: 10px;
}
.phoneNum:hover {
    opacity: 0.5;
}
.email{
    font-size: 10px; 
}
.email:hover {
    opacity: 0.5;
}
.linkedIn{
    font-size: 10px;
}
.linkedIn:hover {
    opacity: 0.5;
}

.mobile-foot{
    display: none;
}

@media screen and (max-width: 500px){
    .mobile-foot{
        display: flex;
    }
    .mobile-footer-list {
        list-style: none;
        padding-left: 20px;
        padding-right: 20px;
    }
    .phoneNum {
        font-size: 16px;
    }
    .email{
        font-size: 16px; 
    }
    .linkedIn{
        font-size: 16px;
    }
    .footer-list{
        display: none;
    }
}