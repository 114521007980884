.Designs{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 3%;   
}


/* The header */
.designHeader{
    margin-top: 150px;
    margin-bottom: 50px;
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    width: 75%;
}
.design-title{
    font-size: 30px;
    font-weight: 600;
}


/* Blog Cards */
.designCard {
    position: relative;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.designContent {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 50px;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: left;
    align-items: center;
    color: #242838;
    border-left: 2px solid #a7aab1;
    border-bottom: 2px solid #a7aab1;
}
.designContent:hover {
    box-shadow: 16px 0px 16px 0px rgba(0,0,0,0.2);
}
.designTitle {
    font-size: 25px;
    font-weight: 600;
    color: #242838;
    margin-top: 20px;
}
.designImageContainer {
    display: flex;
    float: right;
}
.designImage {
    width: 125px;
    height: 125px;
    padding-right: 10px;
}
.designImage:hover{
    opacity: 0.5;
    cursor: pointer;
}
.designText {
    width: 80%;
    text-align: left;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 50px;
}
.preview {
    font-size: 15px;
    opacity: .75;
}
.date {
    font-size: 12px;
    padding-top: 20px;
    opacity: .75;
}

.closeButton {
    color: #ababab;
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 30px;
    margin-left: 95%;
    margin-top: 1%;
}
.closeButton:hover{
    opacity: 0.5;
}

.popup{
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0,0,0,0.9);
}
.popupInner{
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
}
.popupImage{
    margin: auto;
    max-height: 90vh;
}


@media screen and (max-width: 1000px){
    .designTitle{
        font-size: 20px;
    }
    .description{
        font-size: 12px;
    }
    .popupImage{
        max-height: 75vh;
    }
}

@media screen and (max-width: 800px){
    .designImage{
        width: 100px;
        height: 100px;
    }
    .popupImage{
        max-height: 50vh;
    }
    .closeButton{
        margin-left: 90%;
    }
}

@media screen and (max-width: 550px){
    .designTitle{
        font-size: 15px;
    }
    .description{
        font-size: 10px;
    }
    .date{
        font-size: 8px;
    }
    .designImage{
        width: 75px;
        height: 75px;
    }
    .designCard{
        width: 90%;
    }
}


@media screen and (max-width: 500px){
    .popupImage{
        max-height: 35vh;
    }
}


@media screen and (max-width: 400px){
    .designTitle{
        font-size: 12px;
    }
    .date{
        font-size: 8px;
    }
    .designImage{
        width: 50px;
        height: 50px;
    }
    .designText{
        padding-right: 30px;
    }
    .description{
        font-size: 6px;
    }
    .closeButton{
        margin-left: 80%;
    }
}

@media screen and (max-width: 350px){
    .popupImage{
        max-height: 25vh;
    }
}

@media screen and (max-width: 275px){
    .popupImage{
        max-height: 15vh;
    }
}