.Blogs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 3%;
}

/* The header */
.blogHeader{
    margin-top: 150px;
    margin-bottom: 50px;
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    width: 75%;
}
.devLogo {
    color: #242838;
    display: flex;
    flex-direction: column;
    padding-right: 15px;
}
.devLogo:hover {
    opacity: 0.5;
}
.blog-title{
    font-size: 30px;
    font-weight: 600;
}

/* Blog Cards */
.blogCard {
    position: relative;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.blogContent {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 50px;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: left;
    align-items: center;
    color: #242838;
    border-left: 2px solid #a7aab1;
    border-bottom: 2px solid #a7aab1;
}
.blogContent:hover {
    box-shadow: 16px 0px 16px 0px rgba(0,0,0,0.2);
}
.blogLink {
    font-size: 25px;
    font-weight: 600;
    color: #242838;
    margin-top: 20px;
}
.blogImageContainer{
    display: flex;
    float: right;
}
.blogImage {
    width: 125px;
    height: 125px;
    padding-right: 10px;
}
.blogText {
    width: 80%;
    text-align: left;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 50px;
}
.blogText a:hover{
    opacity: 0.5;
}
.preview{
    font-size: 15px;
    opacity: .75;
}
.dateAndMinutes{
    font-size: 12px;
    padding-top: 20px;
    opacity: .75;
}


@media screen and (max-width: 1000px){
    .blogLink{
        font-size: 20px;
    }
    .preview{
        font-size: 12px;
    }
}

@media screen and (max-width: 800px){
    .blogImage{
        width: 100px;
        height: 100px;
    }
}

@media screen and (max-width: 550px){
    .blogLink{
        font-size: 15px;
    }
    .preview{
        font-size: 10px;
    }
    .dateAndMinutes{
        font-size: 8px;
    }
    .blogImage{
        width: 75px;
        height: 75px;
    }
    .blogCard{
        width: 90%;
    }
}

@media screen and (max-width: 550px){
    .preview{
        display: none;
    }
}

@media screen and (max-width: 400px){
    .blogLink{
        font-size: 12px;
    }
    .dateAndMinutes{
        font-size: 8px;
    }
    .blogImage{
        width: 50px;
        height: 50px;
    }
    .blogText{
        padding-right: 30px;
    }
}