.nav-bar{
    list-style-type: none;
    display: inline-flex;
    padding-right: 25px;
    float:right;
    padding-top: 25px;
}
.nav-bar-list{
    padding-left: 20px;
    padding-right: 20px;
}
.nav-bar-link{
    position: relative;
    font-size: 18px;
    color:#242838;
    display: block;
    width:100%;
    opacity: 0.6;
}
.nav-bar-link:hover{
    opacity: 1;
}
.nav-bar-link::after{
    content: "";
    height: 2px;
    width: 100%;
    background-color: #242838;
    display: block;
    position: absolute;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.35s ease;
}
.nav-bar-link:hover::after{
    transform: scaleX(1);
    transform-origin: left;
}

.right-side-nav {
    font-size: 30px;
    font-weight: bold;
    float: left;
    display: inline-flex;
    padding-left: 25px;
}

/* Dropdown button */
.dropdown {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}
.dropbtn {
    font-size: 18px;
    font-family: inherit;
    color: #242838;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 0px;
    width:100%;
    text-decoration: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    opacity: 0.6;
}
.dropbtn:hover {
    opacity: 1;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fcfdff;
    width: 90px;
    height: 35px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border-radius: 2px;
    z-index: 1;
    padding: 8px;
    margin-top: 22px;
} 
/* Links inside the dropdown */
.dropdown-content a {
    float: none;
    color: #243e6b;
    text-decoration: none;
    display: block;
    text-align: center;
    padding-top: 10px;
    font-size: 12px;
    opacity: 0.5;
}
.dropdown-content a:hover {
    opacity: 1;
}
/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}


.mobile {
    color: #242838;
    background-color: #fcfdff;
    border: none;
    cursor: pointer;
    padding-right: 25px;
    display: none;
}


@media screen and (max-width: 1100px){
    .nav-bar.active{
        list-style-type: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        padding-right: 25px;
        margin-top: 90px;
        margin-left: 70%;
        width: 30%;
        background-color: #fcfdff;
        height: 350px;
        box-shadow: -16px 16px 16px 8px rgba(0,0,0,0.2);
        z-index: 2;
    }
    .nav-bar{
        display: none;
    }
    .nav-bar-list{
        padding: 20px 0;
        width: 80%;
    }
    .dropbtn{
        padding-top: 20px;
        padding-bottom: 50px;
    }
    .dropdown-content{
        margin-top: 40px
    }
    .mobile{
        display: block;
    }
    .mobile:hover{
        opacity: .5;
    }
    .right-side-nav{
        font-size: 20px;
        padding-top: 20px;
    }
}

@media screen and (max-width: 500px){
    .right-side-nav{
        font-size: 15px;
        padding-top: 30px;
    }
}

@media screen and (max-width: 300px){
    .right-side-nav{
        font-size: 12px;
        padding-top: 35px;
    }
}