.About {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 90px;
    padding-bottom: 100px;
}

.big-container{
    width: 75%;
    display: flex;
    justify-content: center;
}
.about-container{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    float: left;
}

.greet {
    margin-top: 50px;
    border-bottom: 2px solid #a7aab1;
    padding-bottom: 15px;
    margin-bottom: 5px;
}
.about-text{
    line-height: 25px;
    text-align: left;
    margin-top: 15px;
}

.headshot3{
    width: 100%;
    margin-top: 25px;
}

@media screen and (max-width: 625px){
    .about-text{
        font-size: 12px;
    }
    .greet{
        font-size: 25px;
    }
}
@media screen and (max-width: 500px){
    .about-container{
        width: 80%;
    }
}

@media screen and (max-width: 350px){
    .about-container{
        width: 90%;
    }
    .about-text{
        font-size: 8px;
    }
    .greet{
        font-size: 12px;
    }
}